import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import toast from 'react-hot-toast';
import {
  Button,
  CssBaseline,
  Paper,
  Typography,
  Grid,
  withStyles,
} from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import * as actions from '../../actions';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
  paper: {
    display: 'flex',
    flexGrow: 1,
    padding: `${theme.spacing.unit * 5}px ${theme.spacing.unit * 5}px ${
      theme.spacing.unit * 5
    }px`,
    borderRadius: '7px',
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  alignButton: {
    float: 'right',
    marginTop: 40,
  },
  alignButtonLeft: {
    float: 'left',
    marginTop: 40,
  },

  typography: {
    marginTop: 15,
  },
});

class ModifyFamilyMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfFamilyMembersAllowed: false,
      numberActive: false,
    };
  }

  handleInputChange = (event) => {
    const { target } = event;
    const value = target.checked;

    if (value) {
      this.setState((state) => ({ numberActive: state.numberActive + 1 }));
    } else {
      this.setState((state) => ({ numberActive: state.numberActive - 1 }));
    }

    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  componentDidMount() {
    for (let i = 0; i < this.props.smartFamilyPlan.length; i++) {
      if (
        this.props.smartFamilyPlan[i].name === 'numberOfAdditionalFamilyMembers'
      ) {
        this.setState({
          numberOfFamilyMembersAllowed: this.props.smartFamilyPlan[i].inputData,
        });
      }
    }

    const numberActive = this.props.auth.familyMembers.filter(
      ({ isActive }) => isActive
    ).length;

    this.setState({
      numberActive,
    });
  }

  render() {
    const { handleSubmit, classes } = this.props;

    const familyMembers = this.props.auth.familyMembers[0].name
      ? this.props.auth.familyMembers.map(
          ({ name: { first, last }, _id, isActive }) => (
            <Grid item container key={_id}>
              <Grid item xs={6}>
                <Typography variant="body1">Name</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">Active?</Typography>
              </Grid>
              <Grid item xs={6} className={classes.textField}>
                <Typography variant="h6" color="primary">
                  {`${first} ${last}`}
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.textField}>
                <input
                  name={_id}
                  id="textOptIn"
                  type="checkbox"
                  onChange={this.handleInputChange}
                  defaultChecked={this.state[_id] ? this.state[_id] : isActive}
                />
              </Grid>
            </Grid>
          )
        )
      : [];

    return this.props.auth.familyMembers.length > 0 ? (
      <Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <form
              className={classes.form}
              onSubmit={handleSubmit(() => {
                if (
                  this.state.numberActive >
                  this.state.numberOfFamilyMembersAllowed
                ) {
                  toast.error(
                    `Only ${this.state.numberOfFamilyMembersAllowed} are allowed to be active at a given time`
                  );
                } else {
                  const currentState = this.state;
                  const filteredState = Object.keys(currentState).reduce(
                    (result, key) => {
                      const state = result;
                      if (
                        key !== 'numberOfFamilyMembersAllowed' &&
                        key !== 'numberActive'
                      ) {
                        state[key] = currentState[key];
                      }
                      return state;
                    },
                    {}
                  );

                  this.props.modifyFamilyMembers(filteredState);
                  this.props.history.push('/');
                }
              })}
            >
              <Grid container direction="row" spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h5" color="primary">
                    {' '}
                    Activate or Disable Family Members
                  </Typography>
                </Grid>

                {familyMembers}

                <Grid item xs={12} container direction="row">
                  <Grid item xs={12} container direction="row">
                    <Grid item xs={12} sm={6}></Grid>
                    <Grid item xs={12}>
                      <Link style={{ textDecoration: 'none' }} to="/dashboard">
                        <Button
                          variant="contained"
                          className={classes.alignButtonLeft}
                        >
                          Back
                        </Button>
                      </Link>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.alignButton}
                        type="submit"
                      >
                        {' '}
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </main>
      </Fragment>
    ) : (
      <Fragment>
        <CssBaseline />

        <Paper
          style={{
            maxWidth: 450,
          }}
        >
          <Grid
            container
            direction="column"
            style={{ padding: '5%' }}
            alignItems="center"
          >
            <Grid item style={{ marginBottom: '20px' }}>
              <Typography style={{ textAlign: 'center' }}>
                You do not yet have family members
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary">
                <Link
                  style={{ color: 'white', textDecoration: 'none' }}
                  to="/dashboard"
                >
                  Go Back
                </Link>
              </Button>
            </Grid>
            <br />
          </Grid>
        </Paper>
      </Fragment>
    );
  }
}
ModifyFamilyMembers.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  modifyFamilyMembers: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  smartFamilyPlan: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
};

const ConnectedModifyFamilyMembers = connect(
  (state) => ({
    form: state.form,
    auth: state.auth,
    smartFamilyPlan: state.smartFamilyPlan,
  }),
  actions
)(withRouter(ModifyFamilyMembers));

const ModifyFamilyMembersRedux = reduxForm({
  form: 'profileForm',
  destroyOnUnmount: false,
})(ConnectedModifyFamilyMembers);

export default withStyles(styles)(ModifyFamilyMembersRedux);
