import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  CircularProgress,
  Fab,
  withStyles,
} from '@material-ui/core';
import { Delete, Add } from '@material-ui/icons';
import Resizer from 'react-image-file-resizer';

const styles = (theme) => ({
  inputStyle: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    fontFamily: 'Roboto',
    cursor: 'pointer',
    border: 'solid 1px',
    borderStyle: 'dashed',
    padding: '10px',
  },
  dropzone: {
    width: '100%',
    border: '1px dashed black',
    color: theme.palette.primary.main,
    fontFamily: 'Roboto',
    borderColor: theme.palette.primary.main,
    borderRadius: '5px',
    marginBottom: '10px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
});

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      700,
      700,
      file.type.split('/')[1],
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'file'
    );
  });

function FileInputOhip(props) {
  const [imageData, setImageData] = useState(null);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const FILE_MAX_SIZE = 5e6; // 5MB

  function renderMedia() {
    if (imageData && !props.loading) {
      return (
        <Grid
          item
          container
          spacing={1}
          style={{
            backgroundColor: '#eeeeee',
            padding: '10px',
          }}
        >
          <Grid item xs={10}>
            <img src={imageData} alt="Uploaded" width="100%" />
          </Grid>
          <Grid item xs={2}>
            <Fab
              size="small"
              color="secondary"
              aria-label="Delete"
              type="button"
              title="Remove"
              onClick={() => {
                setImageData(null);
                props.setImageFile(null);
              }}
            >
              <Delete />
            </Fab>
          </Grid>
        </Grid>
      );
    }
    if (props.loading && imageData) {
      return (
        <Grid
          item
          container
          xs={10}
          style={{
            position: 'relative',
          }}
        >
          <Grid
            item
            xs={12}
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              bottom: '0',
              right: '0',
              backgroundColor: 'rgba(173, 173, 173, 0.5)', // gray background with 50% opacity
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress size={35} style={{ color: 'white' }} />
          </Grid>
          <img src={imageData} alt="Uploaded" width="100%" />
        </Grid>
      );
    }
    return null;
  }

  const handleClick = () => {
    document.getElementById('fileInput').click();
  };

  const handleFile = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.target.files[0];
    if (file.size < FILE_MAX_SIZE) {
      setRejectedFiles([]);

      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;

        setImageData(base64String);
      };
      reader.readAsDataURL(file);
      const image = await resizeFile(file);
      props.setImageFile(image);
    } else {
      setRejectedFiles([...rejectedFiles, { file }]);
    }
  };

  return (
    <Grid container direction="row">
      {/** this blocks the user from being able to upload more than one picture;
       * even though the component is set to accept only one picture at a time,
       * the user can still upload more than one picture if the component is not disabled
       */}
      {!imageData && (
        <div
          onClick={handleClick}
          onDrop={handleFile}
          onDragStart={(e) => {
            e.preventDefault();
            e.dataTransfer.clearData();
          }}
          onDragOver={(e) => e.preventDefault()}
          className={props.classes.dropzone}
        >
          <input
            type="file"
            id="fileInput"
            accept="image/*;capture=camera"
            style={{ display: 'none' }}
            onChange={handleFile}
          />
          <Grid item xs={12} container direction="row" justify="center">
            <Grid item style={{ margin: '10px 0px' }}>
              <Typography align="center">
                Drag files here or click to upload (5MB Max.)
              </Typography>
            </Grid>
            <Grid
              item
              container
              justify="center"
              style={{ marginBottom: '10px' }}
            >
              <Fab color="primary" aria-label="add">
                <Add />
              </Fab>
            </Grid>
          </Grid>
        </div>
      )}
      {renderMedia()}
      {rejectedFiles.length > 0 && (
        <Grid item xs={12} style={{ marginTop: '10px' }}>
          <Typography color="error">
            {rejectedFiles[0].file.name} is not a valid image.
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

FileInputOhip.propTypes = {
  setImageFile: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(FileInputOhip);
