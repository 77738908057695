import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Resizer from 'react-image-file-resizer';
import {
  Grid,
  Typography,
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  LinearProgress,
  Fab,
  Paper,
  IconButton,
  CircularProgress,
  withStyles,
} from '@material-ui/core';
import {
  Image,
  Info,
  Add,
  CheckCircle,
  Publish,
  Cancel,
  Delete,
} from '@material-ui/icons';

import PathBuilder from '../../api/media-path-builder';
import * as actions from '../../actions';

const styles = (theme) => ({
  inputStyle: {
    cursor: 'pointer',
  },
  listItem: {
    padding: '5px',
    width: '300px',
    minWidth: '100%',
  },
  dropzone: {
    border: '1px dashed black',
    color: theme.palette.primary.main,
    fontFamily: 'Roboto',
    borderColor: theme.palette.primary.main,
    borderRadius: '5px',
    marginBottom: '10px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
});

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      700,
      700,
      file.type.split('/')[1],
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'file'
    );
  });

export const ApptFileUploadTest = (props) => {
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [files, setFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState({});
  const [totalFileSize, setTotalFileSize] = useState(0);
  const [hasUploadedImages, sethasUploadedImages] = useState(false);
  const MAX_TOTAL_SIZE = 9e6; // 9MB
  const FILE_MAX_SIZE = 3e6; // 3MB
  const MAX_FILE_NUM = 3;
  const allowedTypes = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    // 'application/pdf',
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];

  useEffect(() => {
    if (props.appointmentInfo.hasUploadedFiles) {
      sethasUploadedImages(true);
    }
  }, [props.appointmentInfo.hasUploadedFiles]);

  const handleClick = () => {
    document.getElementById('fileInput').click();
  };

  const addFiles = (newFiles) => {
    let newSize = totalFileSize;
    const updated = [...files];
    const rejected = [];
    if (updated.length + newFiles.length <= MAX_FILE_NUM) {
      newFiles.forEach(async (file) => {
        if (
          newSize + file.size <= MAX_TOTAL_SIZE &&
          file.size <= FILE_MAX_SIZE
        ) {
          updated.push(file);
          newSize += file.size;
        } else {
          rejected.push({ file, error: 'File size limit exceeded' });
        }
      });
    } else {
      rejected.push({ file: newFiles[0], error: 'File limit exceeded' });
    }

    setFiles(updated);
    setRejectedFiles([...rejectedFiles, ...rejected]);
    setTotalFileSize(newSize);
  };

  const handleFileChange = (e) => {
    addFiles(Array.from(e.target.files));
  };

  const handleCloseDialog = () => {
    setFiles([]);
    setTotalFileSize(0);
    setOpenUploadDialog(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const newFiles = Array.from(e.dataTransfer.files);

    const filteredFiles = newFiles.filter((file) =>
      allowedTypes.includes(file.type)
    );

    addFiles(filteredFiles);
  };

  const handleDelete = (name) => {
    const filteredFiles = files.filter((file) => file.name !== name);
    const totalSizeOfNewFiles = filteredFiles.reduce(
      (total, file) => total + file.size,
      0
    );
    setFiles(filteredFiles);
    setTotalFileSize(totalSizeOfNewFiles);
  };

  async function uploadFiles() {
    const pathBuilder = PathBuilder;
    let path;

    setLoading(true);
    setOpenUploadDialog(false);

    const compressedFiles = await Promise.all(files.map(resizeFile));

    const promises = compressedFiles.map(async (file) => {
      const formData = new FormData();
      let newFileName = `${file.name.replace(/(_| )/g, '-')}`;

      // detect length of file name and truncate if necessary, but keep extension
      if (newFileName.length > 50) {
        newFileName = `${newFileName.slice(0, 50)}${newFileName.slice(
          newFileName.lastIndexOf('.')
        )}`;
      }

      const newFile = new File([file], newFileName, {
        type: file.type,
      });
      path = pathBuilder.appointmentFilePath(
        props.appointmentInfo.patientId,
        props.appointmentInfo.appointmentId,
        newFileName
      );

      formData.append('file', newFile);
      formData.append('role', props.auth.role);
      return axios
        .post(path, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          const status = res.response ? res.response.status : res.status;
          if (status >= 400) {
            setUploadStatus((prevState) => ({
              ...prevState,
              [file.name]: 'failed',
            }));
          } else {
            setUploadStatus((prevState) => ({
              ...prevState,
              [file.name]: 'uploaded',
            }));
          }
        })
        .catch(() => {
          setUploadStatus((prevState) => ({
            ...prevState,
            [file.name]: 'failed',
          }));
        });
    });

    await Promise.all(promises);

    setLoading(false);

    const reqPath = pathBuilder.appointmentDirPath(
      props.appointmentInfo.patientId,
      props.appointmentInfo.appointmentId
    );
    const fileList = await axios.get(reqPath);
    if (fileList.data) {
      await axios.post('/api/update_appointment_files', {
        fileList: fileList.data.files,
        apptId: props.appointmentInfo.appointmentId,
      });
      props.getAppointmentHistory(props.appointmentInfo.patientId);
      sethasUploadedImages(true);
    }
  }

  const renderIcon = (status) => {
    switch (status) {
      case 'uploaded':
        return <CheckCircle style={{ color: 'green' }} />;
      case 'failed':
        return <Cancel style={{ color: 'red' }} />;
      default:
        return loading ? (
          <CircularProgress
            size={15}
            color="primary"
            style={{
              marginLeft: 5,
            }}
          />
        ) : (
          <Publish color="primary" />
        );
    }
  };

  return (
    <Grid container>
      {!hasUploadedImages ? (
        <Grid item xs={12} style={{ marginBottom: '20px' }}>
          <Typography variant="h6" color="primary" align="center">
            You may upload images that are useful for appointment purposes.
          </Typography>
        </Grid>
      ) : (
        <Grid item xs={12} style={{ marginBottom: '20px' }}>
          <Typography variant="h6" color="primary" align="center">
            You can view your uploaded files in your current appointment below.
          </Typography>
        </Grid>
      )}
      {files.length > 0 || props.appointmentInfo.hasUploadedFiles ? (
        <Grid item container>
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center"></Box>
            {files.map((doc, index) => (
              <Paper
                elevation={3}
                key={`file-${index}`}
                style={{ marginBottom: '6px' }}
              >
                <Grid
                  item
                  xs={12}
                  container
                  alignItems="center"
                  className={props.classes.listItem}
                >
                  <Grid item container xs={1} alignItems="center">
                    {renderIcon(uploadStatus[doc.name])}
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="body2" noWrap>
                      {uploadStatus[doc.name] !== 'uploaded' &&
                      uploadStatus[doc.name] !== 'failed' &&
                      loading
                        ? 'Scanning file...'
                        : doc.name}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            ))}
          </Grid>
          {rejectedFiles.length > 0 && (
            <Grid item xs={12} sm={6}>
              <Typography align="center" color="primary">
                Rejected files:
              </Typography>
              <ul style={{ overflow: 'visible' }}>
                {rejectedFiles.map(({ file, error }, i) => (
                  <li key={i}>
                    {file.name} - {error}
                  </li>
                ))}
              </ul>
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid item xs={12} container justify="center">
          <Button
            startIcon={<Image />}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => setOpenUploadDialog(true)}
          >
            Upload Images
          </Button>
        </Grid>
      )}
      <Dialog
        fullWidth
        maxWidth="md"
        open={openUploadDialog}
        // onClose={() => setOpenUploadDialog(false)}
      >
        <DialogTitle>
          <Typography variant="h5" color="primary">
            Upload Files
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12} style={{ marginBottom: '20px' }}>
            <List dense>
              <ListItem>
                <ListItemIcon>
                  <Info color="primary" />
                </ListItemIcon>
                <ListItemText primary="File size must be less than 3MB." />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Info color="primary" />
                </ListItemIcon>
                <ListItemText primary="Files must be in .jpg, .jpeg or .png format (images only)." />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Info color="primary" />
                </ListItemIcon>
                <ListItemText primary="Total file size must be 9MB." />
              </ListItem>
            </List>
          </Grid>
          <div
            onClick={handleClick}
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
            className={props.classes.dropzone}
          >
            <input
              type="file"
              id="fileInput"
              accept="image/jpeg,image/png,image/jpg"
              // multiple
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <Grid item xs={12} container direction="row" justify="center">
              <Grid item style={{ margin: '10px 0px' }}>
                <Typography align="center">
                  Drag files here or click to upload.
                </Typography>
              </Grid>
              <Grid
                item
                container
                justify="center"
                style={{ marginBottom: '10px' }}
              >
                <Fab color="primary" aria-label="add">
                  <Add />
                </Fab>
              </Grid>
            </Grid>
          </div>
          <Grid item container>
            <Grid item xs={12} sm={6}>
              <Box display="flex" alignItems="center">
                <Box width="100%" mr={1}>
                  <LinearProgress
                    variant="determinate"
                    style={{ height: '8px', borderRadius: '5px' }}
                    value={Math.round(
                      (Number(totalFileSize) / Number(MAX_TOTAL_SIZE)) * 100
                    )}
                  />
                </Box>
                <Box minWidth={35}>
                  <Typography variant="body2" color="textSecondary">
                    {(Number(totalFileSize) / 1e6).toFixed(2)}MB
                  </Typography>
                </Box>
              </Box>
              {files.map((doc, index) => (
                <Paper
                  elevation={3}
                  key={`file-${index}`}
                  style={{ marginBottom: '6px' }}
                >
                  <Grid
                    item
                    xs={12}
                    container
                    alignItems="center"
                    className={props.classes.listItem}
                  >
                    <Grid item container xs={1} alignItems="center">
                      {renderIcon(uploadStatus[doc.name])}
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="body2" noWrap>
                        {uploadStatus[doc.name] !== 'uploaded' &&
                        uploadStatus[doc.name] !== 'failed' &&
                        loading
                          ? 'Scanning file...'
                          : doc.name}
                      </Typography>
                    </Grid>
                    <Grid item container xs={1} justify="flex-end">
                      <IconButton
                        size="small"
                        disabled={loading}
                        onClick={() => handleDelete(doc.name)}
                      >
                        <Delete />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Paper>
              ))}
            </Grid>
            {rejectedFiles.length > 0 && (
              <Grid item xs={12} sm={6}>
                <Typography align="center" color="primary">
                  Rejected files:
                </Typography>
                <ul style={{ overflow: 'visible' }}>
                  {rejectedFiles.map(({ file, error }, i) => (
                    <li key={i}>
                      {file.name} - {error}
                    </li>
                  ))}
                </ul>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>close</Button>
          <Button
            variant="contained"
            onClick={uploadFiles}
            color="primary"
            disabled={files.length === 0}
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const ConnectedApptFileUploadTest = connect(
  mapStateToProps,
  actions
)(ApptFileUploadTest);

export default withStyles(styles)(ConnectedApptFileUploadTest);
