import React, { Component, Fragment } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  Button,
  Paper,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Snackbar,
  withStyles,
} from '@material-ui/core';
import { passwordStrength } from 'check-password-strength';
import logoMain from '../../SnapMedLogoMain.png';
import logoMainRetina from '../../SnapMedLogoMainRetina.png';

import PasswordStrengthBar from '../../commonform/PasswordStrengthBar';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.down(400 + theme.spacing.unit * 3 * 2)]: {
      width: 350,
      marginBottom: '65px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginBottom: '65px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '30px',
    borderRadius: '7px',
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    margin: '30px 0px',
  },
  fieldSpacing: {
    marginBottom: '8px',
  },
});

class ResetPasswordPharmacy extends Component {
  constructor() {
    super();

    this.state = {
      username: '',
      password: '',
      confirmPassword: '',
      updated: false,
      isLoading: true,
      error: false,
      responseMessage: '',
      disableButton: true,
      strength: { id: -1 },
      validationError: true,
      validationErrorMessage: 'Blank',
    };
  }

  validateNewPassword() {
    if (
      this.state.password !== this.state.confirmPassword &&
      this.state.confirmPassword
    ) {
      this.setState({
        validationError: 'Match',
        validationErrorMessage: 'Passwords must match',
        disableButton: true,
      });
    } else if (this.state.strength.id < 2) {
      this.setState({
        validationError: 'Strength',
        validationErrorMessage: 'Password not strong enough',
        disableButton: true,
      });
    } else if (!this.state.password || !this.state.confirmPassword) {
      this.setState({
        validationError: 'Blank',
        disableButton: true,
      });
    } else {
      this.setState({
        validationError: false,
        validationErrorMessage: '',
        disableButton: false,
      });
    }
  }

  async componentDidMount() {
    await axios
      .get('/api/auth/reset/pharmacy', {
        params: { resetPasswordToken: this.props.match.params.token },
      })
      .then((response) => {
        this.setState({
          username: response.data.username,
          updated: false,
          isLoading: false,
          error: false,
        });
      })
      .catch(() => {
        this.setState({
          updated: false,
          isLoading: false,
          error: true,
        });
      });
  }
  handleClose = () => {
    this.setState({
      updated: false,
    });
    this.props.history.push('/pharmacy');
  };

  updatePassword = (e) => {
    e.preventDefault();
    axios
      .put('/api/auth/update-pharmacy-password-via-email', {
        username: this.state.username,
        password: this.state.password,
      })
      .then((response) => {
        this.setState({
          disableButton: true,
          updated: true,
          error: false,
          responseMessage: response.data,
        });
      })
      .catch(() => {
        this.setState({
          updated: false,
          error: true,
        });
      });
  };

  render() {
    const {
      password,
      confirmPassword,
      error,
      isLoading,
      updated,
      validationError,
      validationErrorMessage,
      responseMessage,
      disableButton,
      strength,
    } = this.state;

    const { classes } = this.props;

    return (
      <Fragment>
        {error && (
          <div>
            <h4>Reset link has expired.</h4>
          </div>
        )}
        {isLoading && (
          <div>
            <h4>Loading data...</h4>
          </div>
        )}
        {!error && !isLoading && (
          <Grid xs={12} container>
            <main className={classes.layout}>
              <Paper className={classes.paper}>
                <img
                  alt="SnapMed Logo"
                  src={logoMain}
                  srcSet={`${logoMainRetina} 2x,`}
                  style={{ marginBottom: '15px' }}
                />
                <Grid item xs={12} style={{ margin: '10px 0px' }}>
                  <Typography variant="h5" color="primary">
                    Reset Your Password
                  </Typography>
                </Grid>
                <form className={classes.form} onSubmit={this.updatePassword}>
                  <Grid item xs={12} className={classes.fieldSpacing}>
                    <TextField
                      id="password"
                      label="New password"
                      value={password}
                      onChange={async (e) => {
                        const pw = e.target.value;
                        const strengthEval = passwordStrength(pw);
                        this.setState({
                          password: pw,
                          strength: strengthEval,
                        });
                        this.validateNewPassword();
                      }}
                      type="password"
                      fullWidth
                      error={validationError === 'Strength'}
                      helperText={
                        validationError === 'Strength' && validationErrorMessage
                      }
                    />
                  </Grid>
                  <PasswordStrengthBar strength={strength} />
                  <Grid item xs={12} className={classes.fieldSpacing}>
                    <TextField
                      id="confirmPassword"
                      label="Confirm new password"
                      value={confirmPassword}
                      onChange={async (e) => {
                        this.setState({
                          confirmPassword: e.target.value,
                        });
                        this.validateNewPassword();
                      }}
                      type="password"
                      fullWidth
                      error={validationError === 'Match'}
                      helperText={
                        validationError === 'Match' && validationErrorMessage
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      className={classes.submit}
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={disableButton}
                      fullWidth
                    >
                      Submit
                      {disableButton && !validationError && (
                        <CircularProgress
                          size={15}
                          color="white"
                          style={{
                            marginLeft: 5,
                          }}
                        />
                      )}
                    </Button>
                  </Grid>
                </form>
                <Snackbar
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  open={updated}
                  onClose={this.handleClose}
                  autoHideDuration={2500}
                  message={responseMessage}
                />
              </Paper>
            </main>
          </Grid>
        )}
      </Fragment>
    );
  }
}
ResetPasswordPharmacy.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
export default withStyles(styles)(ResetPasswordPharmacy);
