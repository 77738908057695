import React, { Component, Fragment } from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import socketIOClient from 'socket.io-client';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Button,
  CssBaseline,
  FormControl,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
  FormControlLabel,
  Dialog,
  Checkbox,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  OutlinedInput,
  FormHelperText,
  IconButton,
  withStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import '../../../styles/doctorRoomStyles.css';
import { FETCH_APPOINTMENT, DOCTORJAAS } from '../../../actions/types';
import DoctorVideoChat from './videoChat/DoctorVideoChat';
import IdleTimer from '../../commonform/IdleTimer';
import * as actions from '../../../actions';
import {
  generalSicknoteField,
  schoolSicknoteField,
  prescriptionFinalField,
  finalIdValidationField,
} from './renderFinalValidationFields';
import constants from '../../../constants';

const styles = () => ({
  root: {
    flexGrow: 1,
    padding: 15,
  },
  popUpFirstItem: {
    margin: 5,
  },
  popUpSecondItem: {
    margin: 5,
    marginBottom: 25,
    marginTop: 50,
  },
  popUpSubmittingButtons: {
    float: 'right',
    margin: 5,
  },
  popUpBorderRadius: {
    borderRadius: 10,
  },
  submitAppointmentButton: {
    position: 'absolute',
    right: '2%',
    marginTop: '2.5%',
    marginBottom: '2.5%',
  },
  noteReminder: {
    position: 'relative',
    float: 'right',
  },
  note: {
    position: 'relative',
    marginTop: '7%',
    float: 'right',
  },
  paper: {
    borderRadius: '10px',
    marginTop: '10%',
    marginLeft: '15%',
    width: 500,
    height: '80%',
  },
  video: {
    borderRadius: '10px',
    border: '1px solid black',
    width: '90%',
    height: 500,
    maxWidth: 700,
    minWidth: 260,
    maxHeight: 500,
    marginLeft: '5%',
  },
});

const FinalCheckbox = ({ input, label, disableCheckbox }) => (
  <FormControl>
    {label}
    <FormControlLabel
      control={
        <Checkbox
          {...input}
          color="primary"
          checked={input.value}
          disabled={disableCheckbox}
        />
      }
    />
  </FormControl>
);

const FinalCheckboxPrescription = ({ input, label, finalizePrescription }) => (
  <FormControl>
    {label}
    <FormControlLabel
      control={
        <Checkbox
          {...input}
          color="primary"
          checked={finalizePrescription}
          inputProps={{
            checked: finalizePrescription,
          }}
        />
      }
    />
  </FormControl>
);

const FinalCheckboxSickNote = ({ input, finalizeSickNote }) => (
  <FormControl>
    <FormControlLabel
      control={
        <Checkbox
          {...input}
          color="primary"
          checked={finalizeSickNote}
          inputProps={{
            checked: finalizeSickNote,
          }}
        />
      }
    />
  </FormControl>
);

const FinalCheckboxSchoolSickNote = ({
  input,
  label,
  finalizeSchoolSickNote,
}) => (
  <FormControl>
    {label}
    <FormControlLabel
      control={
        <Checkbox
          {...input}
          color="primary"
          checked={finalizeSchoolSickNote}
          inputProps={{
            checked: finalizeSchoolSickNote,
          }}
        />
      }
    />
  </FormControl>
);

const DiagnosticCode = ({ name, input, options, meta: { touched, error } }) => {
  const { onChange } = input;
  return (
    <FormControl variant="outlined" fullWidth>
      <Autocomplete
        // {...input}
        id={name}
        size="small"
        value={input.value || []}
        options={options}
        onChange={(e, newValue) => {
          onChange(newValue);
        }}
        getOptionLabel={(option) => option.diagnosis}
        renderInput={(params) => (
          <TextField {...params} label="Diagnostic Code" variant="outlined" />
        )}
      />
      {touched && error ? (
        <FormHelperText>
          <span style={{ color: '#f44336' }}>{error}</span>
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};

class DoctorAppointmentRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      socket: socketIOClient(
        `${constants.REACT_APP_HOST_NAME}/snapmed_doctors`,
        { transports: ['websocket'] }
      ),
      submitAppointment: false,
      disablePatientAppointmentButton: true,
      disconnectCall: false,
      callDuration: '',
      textChat: [],
    };
  }

  updateTextChat = (message) => {
    this.setState((prevState) => ({
      textChat: [...prevState.textChat, message],
    }));
  };

  async componentDidMount() {
    if (!this.props.appointmentParams.appointmentId) {
      // TODO use this to create an action
      await axios
        .get('/api/get_current_session_doctor')
        .then((res) => {
          const jaasData = {
            sessionId: res.data.jaasCredentials.sessionId,
            docToken: res.data.jaasCredentials.docToken,
          };
          this.props.dispatch({
            type: FETCH_APPOINTMENT,
            payload: res.data,
          });
          this.props.dispatch({
            type: DOCTORJAAS,
            payload: jaasData,
          });
        })
        .catch(() => {
          window.location.replace('/doctors');
        });
    }

    toast(
      (t) => (
        <Grid container item xs={12} alignItems="center">
          <Grid item xs={11}>
            <Typography color="primary">
              If you believe this is an emergency, please indicate to the
              patient to call 911 or go to their nearest Medical Facility.
            </Typography>
          </Grid>
          <Grid item xs={1} container justify="flex-end">
            <IconButton
              variant="outlined"
              color="primary"
              onClick={() => toast.dismiss(t.id)}
              size="small"
            >
              <Close color="primary" />
            </IconButton>
          </Grid>
        </Grid>
      ),
      {
        position: 'bottom-center',
        duration: Infinity,
        style: { maxWidth: 'fit-content' },
      }
    );
  }

  componentDidUpdate = () => {
    if (this.state.submitAppointment) {
      window.pagehide = undefined;
      window.popstate = undefined;
    }
  };

  componentWillUnmount() {
    this.props.leaveRoom();
    this.setState({ socket: '' });
  }

  // eslint-disable-next-line class-methods-use-this
  renderFinalValidation(
    name,
    type,
    label,
    finalizePrescription,
    finalizeSickNote,
    finalizeSchoolSickNote,
    disableCheckbox
  ) {
    const componentMap = {
      prescription_final_validation_yes: FinalCheckboxPrescription,
      general_sicknote_final_validation_yes: FinalCheckboxSickNote,
      school_sicknote_final_validation_yes: FinalCheckboxSchoolSickNote,
    };

    const comp = componentMap[name] || FinalCheckbox;

    return (
      <Field
        name={name}
        type={type}
        label={label}
        finalizePrescription={finalizePrescription}
        finalizeSickNote={finalizeSickNote}
        finalizeSchoolSickNote={finalizeSchoolSickNote}
        disableCheckbox={disableCheckbox}
        component={comp}
      />
    );
  }

  disableSubmitButton() {
    const { form } = this.props;

    if (form.noteForm && form.noteForm.values) {
      const { finalizePrescription, finalizeSchoolSickNote, finalizeSickNote } =
        this.props;

      const {
        diagnosticCode,
        appointmentCode,
        final_id_validation_field_na: finalIdValidationFieldNa,
        final_id_validation_field_yes: finalIdValidationFieldYes,
        school_sicknote_final_validation_na: schoolSicknoteFinalValidationNa,
        general_sicknote_final_validation_na: generalSicknoteFinalValidationNa,
        prescription_final_validation_na: prescriptionFinalValidationNa,
      } = form.noteForm.values;

      const finalIdValidation =
        finalIdValidationFieldNa || finalIdValidationFieldYes;

      const conditionCheck = () => {
        if (
          finalizePrescription &&
          finalizeSchoolSickNote &&
          finalizeSickNote
        ) {
          return false;
        }

        if (
          finalizePrescription &&
          finalizeSickNote &&
          schoolSicknoteFinalValidationNa
        ) {
          return false;
        }

        if (
          finalizePrescription &&
          schoolSicknoteFinalValidationNa &&
          generalSicknoteFinalValidationNa
        ) {
          return false;
        }

        if (
          finalizePrescription &&
          finalizeSchoolSickNote &&
          generalSicknoteFinalValidationNa
        ) {
          return false;
        }

        if (
          prescriptionFinalValidationNa &&
          schoolSicknoteFinalValidationNa &&
          generalSicknoteFinalValidationNa
        ) {
          return false;
        }

        if (
          finalizeSchoolSickNote &&
          finalizeSickNote &&
          prescriptionFinalValidationNa
        ) {
          return false;
        }

        if (
          finalizeSickNote &&
          prescriptionFinalValidationNa &&
          schoolSicknoteFinalValidationNa
        ) {
          return false;
        }

        if (
          prescriptionFinalValidationNa &&
          generalSicknoteFinalValidationNa &&
          finalizeSchoolSickNote
        ) {
          return false;
        }
        return true;
      };

      if (!this.props.appointmentParams.appointment.ohipAppointment) {
        return conditionCheck();
      }

      if (!!diagnosticCode && !!appointmentCode && finalIdValidation) {
        return conditionCheck();
      }
    }
    return true;
  }

  disableCheckbox(name) {
    const { form } = this.props;
    if (!form.noteForm) return true;
    if (
      name === 'final_id_validation_field_yes' &&
      form.noteForm.values &&
      form.noteForm.values.final_id_validation_field_na
    ) {
      return true;
    }
    if (
      name === 'final_id_validation_field_na' &&
      form.noteForm.values &&
      form.noteForm.values.final_id_validation_field_yes
    ) {
      return true;
    }
    if (
      name === 'prescription_final_validation_na' &&
      this.props.finalizePrescription
    ) {
      return true;
    }

    if (
      name === 'school_sicknote_final_validation_na' &&
      this.props.finalizeSchoolSickNote
    ) {
      return true;
    }

    if (
      name === 'general_sicknote_final_validation_na' &&
      this.props.finalizeSickNote
    ) {
      return true;
    }
    return false;
  }

  handleFinalizeAppointment() {
    this.setState({ submitAppointment: true }, () => {
      // const fullChat = document.getElementById('history');
      // const texts = [].map.call(fullChat.querySelectorAll('p'), function (p) {
      //   return p.textContent;
      // });
      // this.props.dispatch(actions.savedTextChatAction(texts));
    });
  }

  // eslint-disable-next-line class-methods-use-this
  formDataRender = ({
    name,
    input,
    placeholder,
    formArray,
    meta: { error },
  }) => (
    <FormControl fullWidth variant="outlined" size="small">
      <InputLabel id={name}> {placeholder} </InputLabel>
      <Select {...input} input={<OutlinedInput label={placeholder} />}>
        {formArray.map((formData) => (
          <MenuItem key={formData.id} value={formData}>
            {formData.name}
          </MenuItem>
        ))}
      </Select>
      <br />
      {error && <span style={{ color: 'red' }}>{error}</span>}
    </FormControl>
  );

  renderFields = (placeholder, name, formArray) => (
    <Field
      name={name}
      type={'text'}
      placeholder={placeholder}
      format={(value) => value || ''}
      onBlur={null}
      formArray={formArray}
      component={this.formDataRender}
    />
  );

  // eslint-disable-next-line class-methods-use-this
  renderDiagnosticCodeField(placeholder, name, type, codes) {
    return (
      <Field
        name={name}
        type={type}
        placeholder={placeholder}
        format={(value) => value || ''}
        onBlur={null}
        component={DiagnosticCode}
        options={codes}
      />
    );
  }

  render() {
    const {
      classes,
      finalizePrescription,
      finalizeSickNote,
      finalizeSchoolSickNote,
      diagnosticCodes,
    } = this.props;
    const { docToken } = this.props.appointmentDoctorJaas;
    const { appointment } = this.props.appointmentParams;

    return (
      <Fragment>
        <CssBaseline />
        <IdleTimer
          portal="Doctor"
          component="Appointment"
          history={this.props.history}
        />
        <div className={classes.root}>
          <Grid container id="main-container-doctor">
            <Grid item xs={12}>
              {this.props.appointmentParams &&
                this.props.appointmentParams.appointmentId &&
                this.props.appointmentDoctorJaas &&
                this.props.appointmentDoctorJaas.docToken && (
                  <DoctorVideoChat
                    docToken={docToken}
                    room={this.props.appointmentParams.appointmentId}
                    getCallDuration={(value) =>
                      this.setState({ callDuration: value })
                    }
                    disconnectCall={this.state.disconnectCall}
                    updateTextChat={(message) => this.updateTextChat(message)}
                    endCallForPatient={() => {
                      this.props.appointmentRoomRemovePatient({
                        appointment: this.props.appointmentParams.appointment,
                      });
                    }}
                    finalizeAppointment={() => {
                      this.handleFinalizeAppointment();
                      this.setState({
                        disconnectCall: true,
                      });
                    }}
                  />
                )}
            </Grid>
            <form
              id="doctor_appointment"
              onSubmit={this.props.handleSubmit(async (values) => {
                const finalValues = {
                  ...values,
                  patientObject: this.props.appointmentParams.patient,
                  appointment: this.props.appointmentParams.appointment,
                  room: this.props.appointmentParams.appointment._id,
                  textChatTranscript: this.state.textChat,
                  patientWasPicked: true,
                  doctorAppointmentTime: this.state.callDuration,
                  doctorNotesEditable:
                    this.props.appointmentParams.appointment
                      .doctorNotesEditable,
                };
                this.props.appointmentRoomHelper(
                  finalValues,
                  this.props.history
                );
                this.props.reset(this.props.form.noteForm);
              })}
            >
              {appointment && appointment.patientRequireSickNote && (
                <Typography
                  variant="subtitle1"
                  color="error"
                  className={classes.noteReminder}
                >
                  *This patient requires a work or school sicknote
                </Typography>
              )}

              <Dialog
                fullscreen
                fullWidth
                open={this.state.submitAppointment}
                onClose={() => {
                  this.setState({ submitAppointment: false });
                }}
              >
                <DialogTitle style={{ color: 'primary' }}>
                  {' '}
                  Would you like to finalize the appointment?
                </DialogTitle>
                <DialogContent classes={classes.dialogPaper}>
                  <DialogContentText>
                    <Grid container direction="row" alignItems="center">
                      <Grid item xs={10}>
                        <Typography>
                          <strong>Please confirm the below:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography
                          style={{
                            fontSize: '0.7em',
                          }}
                        >
                          YES
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography
                          style={{
                            fontSize: '0.7em',
                          }}
                        >
                          N/A
                        </Typography>
                      </Grid>

                      <Grid item xs={10}>
                        {"Has the patient's sick note been completed?"}
                      </Grid>
                      {generalSicknoteField.map(({ name, type, label }) => (
                        <Grid
                          item
                          xs={1}
                          key={name}
                          style={{ justifyContent: 'center' }}
                        >
                          {this.renderFinalValidation(
                            name,
                            type,
                            label,
                            null,
                            finalizeSickNote,
                            null,
                            this.disableCheckbox(name)
                          )}
                        </Grid>
                      ))}
                      <Grid item xs={10}>
                        {"Has the patient's school sick note been completed?"}
                      </Grid>

                      {schoolSicknoteField.map(({ name, type }) => (
                        <Grid item xs={1} key={name}>
                          {this.renderFinalValidation(
                            name,
                            type,
                            null,
                            null,
                            null,
                            finalizeSchoolSickNote,
                            this.disableCheckbox(name)
                          )}
                        </Grid>
                      ))}
                      <Grid item xs={10}>
                        {'Has the prescription been completed and sent?'}
                      </Grid>
                      {prescriptionFinalField.map(({ name, type }) => (
                        <Grid item xs={1} key={name}>
                          {this.renderFinalValidation(
                            name,
                            type,
                            null,
                            finalizePrescription,
                            null,
                            null,
                            this.disableCheckbox(name)
                          )}
                        </Grid>
                      ))}
                      {this.props.appointmentParams.appointment &&
                        this.props.appointmentParams.appointment
                          .ohipAppointment && (
                          <>
                            <Grid item container alignItems="center">
                              <Grid item xs={10}>
                                {"Has the patient's ID been verified?"}
                              </Grid>
                              {finalIdValidationField.map(({ name, type }) => (
                                <Grid item xs={1} key={name}>
                                  {this.renderFinalValidation(
                                    name,
                                    type,
                                    null,
                                    null,
                                    null,
                                    null,
                                    this.disableCheckbox(name)
                                  )}
                                </Grid>
                              ))}
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '10px' }}>
                              <Typography>
                                <strong>
                                  Please select the billing codes for this
                                  appointment:
                                </strong>
                              </Typography>
                            </Grid>
                            <Grid item container spacing={1}>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                style={{ marginTop: 10 }}
                              >
                                <Typography>
                                  {this.renderFields(
                                    'Fee Code',
                                    'appointmentCode',
                                    this.props.appointmentCodes
                                  )}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                style={{ marginTop: 10 }}
                              >
                                <Typography>
                                  {this.renderDiagnosticCodeField(
                                    'Diagnosis Code',
                                    'diagnosticCode',
                                    'input',
                                    diagnosticCodes
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          </>
                        )}
                    </Grid>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    disabled={this.disableSubmitButton()}
                    variant="contained"
                    color="primary"
                    form="doctor_appointment"
                    type="submit"
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
            </form>
          </Grid>
        </div>
      </Fragment>
    );
  }
}

function validate(values) {
  const errors = {};

  function validateSingleEntry(formValues, fields, errorField) {
    let count = 0;
    fields.forEach((field) => {
      if (formValues[field.name]) {
        count++;
      }
    });
    if (count !== 1) {
      errors[errorField] = true;
    }
  }

  validateSingleEntry(values, generalSicknoteField, 'general_note');
  validateSingleEntry(values, schoolSicknoteField, 'school_note');
  validateSingleEntry(values, prescriptionFinalField, 'prescription');

  if (
    !values.prescription_final_validation_yes &&
    !values.prescription_final_validation_na
  ) {
    errors.pristinePres = true;
  } else if (
    values.prescription_final_validation_yes ||
    values.prescription_final_validation_na
  ) {
    errors.pristinePres = false;
  }
  if (
    !values.school_sicknote_final_validation_yes &&
    !values.school_sicknote_final_validation_na
  ) {
    errors.pristineSchool = true;
  } else if (
    values.school_sicknote_final_validation_yes ||
    values.school_sicknote_final_validation_na
  ) {
    errors.pristineSchool = false;
  }
  if (
    !values.general_sicknote_final_validation_yes ||
    !values.general_sicknote_final_validation_na
  ) {
    errors.pristineGen = true;
  } else if (
    values.general_sicknote_final_validation_yes ||
    values.general_sicknote_final_validation_na
  ) {
    errors.pristineGen = false;
  }

  return errors;
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    appointmentDoctorJaas: state.appointmentDoctorJaas,
    appointmentParams: state.appointmentParams,
    form: state.form,
    finalizePrescription: state.finalizePrescription,
    finalizeSickNote: state.finalizeSickNote,
    finalizeSchoolSickNote: state.finalizeSchoolSickNote,
    savedTextChat: state.savedTextChat,
    appointmentCodes: state.smartAppointmentCode,
    diagnosticCodes: state.diagnosticCodes,
  };
}

DoctorAppointmentRoom.propTypes = {
  classes: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  appointmentParams: PropTypes.object.isRequired,
  appointmentDoctorJaas: PropTypes.object.isRequired,
  finalizePrescription: PropTypes.bool.isRequired,
  finalizeSickNote: PropTypes.bool.isRequired,
  finalizeSchoolSickNote: PropTypes.bool.isRequired,
  appointmentCodes: PropTypes.array.isRequired,
  diagnosticCodes: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  appointmentRoomHelper: PropTypes.func.isRequired,
  appointmentRoomRemovePatient: PropTypes.func.isRequired,
  leaveRoom: PropTypes.func.isRequired,
};

FinalCheckbox.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  disableCheckbox: PropTypes.bool,
};

FinalCheckboxPrescription.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  finalizePrescription: PropTypes.bool.isRequired,
};

FinalCheckboxSickNote.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  finalizeSickNote: PropTypes.bool.isRequired,
};

FinalCheckboxSchoolSickNote.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  finalizeSchoolSickNote: PropTypes.bool.isRequired,
};

DiagnosticCode.propTypes = {
  name: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
};

const ConnectedDoctorAppointmentRoom = connect(
  mapStateToProps,
  actions
)(DoctorAppointmentRoom);

const DoctorAppointmentRoomRedux = reduxForm({
  validate,
  form: 'noteForm',
  destroyOnUnmount: false,
})(withRouter(ConnectedDoctorAppointmentRoom));

export default withStyles(styles)(withRouter(DoctorAppointmentRoomRedux));
