import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  CircularProgress,
} from '@material-ui/core';

import * as actions from '../../actions';

class ConsentFormDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      document: '',
      openValidation: false,
      loading: false,
      disableConsentBtn: true,
    };
  }
  componentDidMount = async (prevProps) => {
    const { rxInfo } = this.props;
    if (rxInfo && prevProps !== this.props) {
      const result = await axios.get(`/api/consent-form/${rxInfo.form}`);
      this.setState({ document: result.data.htmlString });
    }
  };

  handleConsent = async () => {
    const { rxInfo } = this.props;
    this.setState({ loading: true });
    try {
      const result = await axios.post('/api/consent-signature', {
        id: rxInfo.id,
      });
      if (result && result.data) {
        this.setState({ loading: false, openValidation: false });
        this.props.close();
        this.props.getAppointmentHistory(this.props.selectedUser._id);
      }
    } catch (err) {
      this.setState({ loading: false });
    }
  };

  scrollCheck = (event) => {
    const bottom =
      Math.floor(event.target.scrollHeight - event.target.scrollTop - 300) <=
      event.target.clientHeight;
    if (bottom) {
      this.setState({ disableConsentBtn: false });
    }
  };

  render() {
    const { document, openValidation } = this.state;
    return (
      <>
        <Dialog
          onClose={this.props.close}
          aria-labelledby="consent-dialog-title"
          open={this.props.open}
          scroll="paper"
          onScroll={this.scrollCheck}
        >
          <DialogTitle id="consent-dialog-title">Consent Review</DialogTitle>
          <DialogContent dividers>
            <Grid
              item
              container
              xs={12}
              justify="center"
              style={{ minWidth: '500px', backgroundColor: '#FFFFFF' }}
            >
              {document ? (
                ReactHtmlParser(document)
              ) : (
                <CircularProgress size={30} color="primary" />
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.close} color="primary">
              <Typography color="error">Close</Typography>
            </Button>
            <Button
              onClick={() => this.setState({ openValidation: true })}
              color="primary"
              variant="outlined"
              disabled={
                this.state.disableConsentBtn || this.state.document === ''
              }
            >
              Consent
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          onClose={() => this.setState({ openValidation: false })}
          aria-labelledby="validation-dialog-title"
          open={openValidation}
          scroll="paper"
        >
          <DialogTitle id="validation-dialog-title">Are you sure?</DialogTitle>
          <DialogContent dividers>
            <Typography>
              {`You acknowledge that all the information in this document has been
              fully explained to you by SnapMED's physician and that you clearly
              understand the points and the information on ACCUTANE/ EPURIS
              provided to you.`}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ openValidation: false })}
              color="primary"
            >
              <Typography color="error">Close</Typography>
            </Button>
            <Button
              onClick={this.handleConsent}
              variant="contained"
              color="primary"
              disabled={this.state.loading}
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedUser: state.selectedUser,
  };
}

ConsentFormDialog.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  rxInfo: PropTypes.object,
  getAppointmentHistory: PropTypes.func,
  selectedUser: PropTypes.object,
};

const ConnectedConsentFormDialog = connect(
  mapStateToProps,
  actions
)(ConsentFormDialog);

export default ConnectedConsentFormDialog;
