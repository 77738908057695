import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Registration from '../registration/Registration';
import Dashboard from '../dashboard/Dashboard';
import ProfileFormRoot from '../profileform/ProfileFormRoot';
import AdditionalFamilyMembers from '../profileform/AdditionalFamilyMembers';
import ModifyFamilyMembers from '../profileform/ModifyFamilyMembers';
import WizardForm from '../medicalform/wizardprofile/WizardForm';
import PaymentPlanRoot from '../payment/PaymentPlanRoot';
import SeeDoctorRoot from '../seeDoctor/SeeDoctorRoot';
import SeeDoctorRootOhip from '../seeDoctor/SeeDoctorRootOhip';
import PatientAppointmentRoom from '../seeDoctor/PatientAppointmentRoom';
import WizardProfileCompletionAcknowledgement from '../medicalform/wizardprofile/WizardProfileCompletionAcknowledgement';
import CheckoutForm from '../payment/CheckoutForm';
import PaymentAcknowledgement from '../payment/PaymentAcknowledgement';
import LoggedOut from '../login/LoggedOut';
import StandardMembership from '../dashboard/StandardMembership';
import Checkout from '../payment/Checkout';
import CheckoutConfirmation from '../payment/CheckoutConfirmation';
import TermsAndConditions from '../../legal/TermsAndConditions';
import PrivacyPolicy from '../../legal/PrivacyPolicy';

import { isAppOpenForBusiness } from '../../utils/checkSchedule';

const ProtectedSymptomsRoute = ({ component: Component, ...rest }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    isAppOpenForBusiness().then((data) => {
      setIsOpen(data.seeingPatients);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <div>Checking schedule...</div>;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isOpen ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

const ProtectedProfileRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      rest.auth.finishedProfile ? <Redirect to="/" /> : <Component {...props} />
    }
  />
);

const PatientRoutes = (props) => (
  <Switch>
    <Route exact path="/" component={Dashboard} />
    <Route
      exact
      path="/dashboard/:toolbarPage?/:editPage?"
      component={Dashboard}
    />
    <ProtectedProfileRoute
      exact
      path="/profileform"
      component={ProfileFormRoot}
      auth={props.auth}
    />
    <Route
      exact={true}
      path="/AdditionalFamilyMembers"
      component={AdditionalFamilyMembers}
    />
    <Route exact={true} path="/standard-plans" component={StandardMembership} />
    <Route exact={true} path="/checkout" component={Checkout} />
    <Route
      exact
      path="/checkout/confirmation"
      component={CheckoutConfirmation}
    />
    <Route
      exact={true}
      path="/ModifyFamilyMembers"
      component={ModifyFamilyMembers}
    />
    <Route
      exact
      path="/paymentacknowledgment/:existing?"
      component={PaymentAcknowledgement}
    />
    <Route exact={true} path="/registration/new" component={Registration} />
    <Route exact={true} path="/logged_out" component={LoggedOut} />
    <Route exact={true} path="/paymentplans" component={PaymentPlanRoot} />
    <Route
      exact={true}
      path="/PaymentPlanMembership/:selectedType/:origin"
      component={CheckoutForm}
    />
    <Route
      exact={true}
      path="/medicalprofileform/:name"
      component={WizardForm}
    />
    <ProtectedSymptomsRoute
      exact
      path="/your_symptoms"
      component={SeeDoctorRoot}
    />
    <ProtectedSymptomsRoute
      exact
      path="/your_ohipsymptoms"
      component={SeeDoctorRootOhip}
    />
    <Route
      exact
      path="/appointmentroomp/:room"
      component={PatientAppointmentRoom}
    />
    <Route
      exact={true}
      path="/completed"
      component={WizardProfileCompletionAcknowledgement}
    />
    <Route
      exact={true}
      path="/TermsAndConditions"
      component={TermsAndConditions}
    />
    <Route exact={true} path="/PrivacyPolicy" component={PrivacyPolicy} />
    <Route exact path="*" component={Dashboard} />
  </Switch>
);

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

PatientRoutes.propTypes = {
  auth: PropTypes.object,
};

ProtectedSymptomsRoute.propTypes = {
  component: PropTypes.func,
};

ProtectedProfileRoute.propTypes = {
  component: PropTypes.func,
};

const ConnectedPatientRoutes = connect(mapStateToProps)(PatientRoutes);

export default ConnectedPatientRoutes;
