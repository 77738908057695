import React, { Component, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { reduxForm, getFormMeta, Field, FormSection } from 'redux-form';
import PropTypes from 'prop-types';
import {
  Button,
  CssBaseline,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Grid,
  Slide,
  Backdrop,
  CircularProgress,
  withStyles,
} from '@material-ui/core';
import { createTextMask } from 'redux-form-input-masks';
import DatePicker from 'react-datepicker';
import DropzoneInputOhip from '../commonform/FileInputOhip';
import cardPlaceholder from '../../images/ohip_card_placeholder.png';
import PathBuilder from '../../api/media-path-builder';
import * as actions from '../../actions';
import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/safariDatePicker.css';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
  paper: {
    display: 'flex',
    flexGrow: 1,
    padding: '1.5rem',
    borderRadius: '7px',
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    float: 'right',
  },
  choices: {
    marginTop: 30,
  },
  title: {
    margin: '30px 0',
  },
  alignButton: {
    marginTop: 40,
    marginBottom: 10,
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  backButton: {
    marginRight: theme.spacing.unit,
  },
  otherField: {
    marginTop: 40,
  },
  textField: {
    backgroundColor: 'aliceblue',
    borderRadius: '5px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

const customOhipNumberMask = {
  9: {
    regExp: /[0-9]/,
  },
  A: {
    regExp: /[A-Za-z]/,
    transform: (char) => char.toUpperCase(),
  },
};

const ohipNumberMask = createTextMask({
  pattern: '9999-999-999-AA',
  guide: true,
  allowEmpty: false,
  stripMask: false,
  maskDefinitions: customOhipNumberMask,
});

const isDateSupported = () => {
  const i = document.createElement('input');
  i.setAttribute('type', 'date');
  return i.type !== 'text';
};

const CustomInput = ({ value, onClick, meta: { touched, error } }) => (
  <TextField
    value={value}
    onClick={onClick}
    // {...input}
    fullWidth
    variant="outlined"
    InputLabelProps={{
      shrink: true,
    }}
    placeholder="yyyy-mm-dd"
    error={touched && error}
    helperText={touched && error}
  />
);

const SafariDatePicker = ({ id, input, meta }) => {
  const [startDate, setStartDate] = useState();
  return (
    <div>
      <DatePicker
        {...input}
        name={id}
        customInput={<CustomInput meta={meta} />}
        dateFormat="yyyy-MM-dd"
        selected={startDate}
        showYearDropdown
        showMonthDropdown
        onChange={(date) => {
          setStartDate(date);
          const newDate = date.toLocaleDateString('en-CA');
          input.onChange(newDate);
        }}
      />
    </div>
  );
};

const OhipNumberField = ({ input, name, type, meta: { touched, error } }) => (
  <TextField
    {...input}
    fullWidth
    variant="outlined"
    type={type}
    id={name}
    error={touched && error}
    helperText={(touched && error) || '####-###-###-AZ'}
  />
);

const OhipExpirationDate = ({ input, meta: { touched, error } }) => (
  <TextField
    {...input}
    fullWidth
    type="date"
    variant="outlined"
    error={touched && error}
    helperText={(touched && error) || 'YYYY-MM-DD'}
    inputProps={{ max: '9999-12-31' }}
  />
);

// const ImageError = ({ meta: { error } }) => (
//   <Typography variant="caption" color="error">
//     {error}
//   </Typography>
// );

class EditOhipCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redOhipCardDialog: false,
      ohipReminder: false,
      imageFile: null,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidMount() {
    setTimeout(() => {
      document.querySelector('#toolbar-section').scrollIntoView({
        behavior: 'smooth',
      });
    }, 500);
  }

  componentDidUpdate(prevProps) {
    // detecting changes in the form to close edit page. (maybe review this later)
    if (
      prevProps.selectedUserMedical.ohipNumber !==
        this.props.selectedUserMedical.ohipNumber ||
      prevProps.selectedUserMedical.ohipExpiration !==
        this.props.selectedUserMedical.ohipExpiration ||
      prevProps.selectedUserMedical.ohipCardImage !==
        this.props.selectedUserMedical.ohipCardImage
    ) {
      this.uploadCardImage();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  renderOhipNumber = () => (
    <Field name="ohipNumber" component={OhipNumberField} {...ohipNumberMask} />
  );

  uploadCardImage = async () => {
    this.setState({ backdrop: true });
    const { imageFile } = this.state;
    const formData = new FormData();
    let newFileName = `${imageFile.name.replace(/(_| )/g, '-')}`;

    // detect length of file name and truncate if necessary, but keep extension
    if (newFileName.length > 50) {
      newFileName = `${newFileName.slice(0, 50)}${newFileName.slice(
        newFileName.lastIndexOf('.')
      )}`;
    }

    const newFile = new File([imageFile], newFileName, {
      type: imageFile.type,
    });
    const path = PathBuilder.patientHealthCardFilePath(
      this.props.selectedUser._id,
      newFileName
    );

    formData.append('file', newFile);
    formData.append('role', this.props.auth.role);
    return axios
      .post(path, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        this.props.editProfile({
          id: this.props.selectedUser._id,
          medicalProfile: {
            ohipCardImage: response.data.newFile,
          },
        });
        this.setState({ backdrop: false });
        this.props.closeEditPage();
      });
  };

  // eslint-disable-next-line class-methods-use-this
  renderOhipExpirationDate = (hasDateSupport) => (
    <Field
      name="ohipExpiration"
      id="ohipExpiration"
      component={
        hasDateSupport ? OhipExpirationDate : SafariDatePicker
      } /* {...ohipExpirationMask} */
    />
  );

  render() {
    const { classes, selectedUserMedical, pristine, invalid } = this.props;

    return (
      <Fragment>
        <CssBaseline />
        <Slide in={true} direction="right">
          <main className={classes.layout}>
            <FormSection name="medicalProfile">
              <Grid container direction="row">
                <Grid item className={classes.title}>
                  <Typography
                    variant="h5"
                    color="primary"
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    Edit OHIP Card Information
                  </Typography>
                </Grid>
                <Grid item container spacing={1}>
                  <Grid item xs={12} sm={5}>
                    <img
                      src={
                        selectedUserMedical.ohipCardImage
                          ? `${this.props.selectedUserMedical.ohipCardImage}`
                          : cardPlaceholder
                      }
                      width="100%"
                    />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <Button
                      variant="outlined"
                      onClick={() => this.setState({ redOhipCardDialog: true })}
                      style={{
                        textTransform: 'none',
                        color: '#ef4242',
                        borderColor: '#ef4242',
                      }}
                    >
                      Click here if you have a Red & White Card
                    </Button>
                  </Grid>
                  <Grid item container xs={12} spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        className={classes.typography}
                        variant="body1"
                      >
                        OHIP Number{' '}
                        <span
                          style={{
                            color: 'green',
                            fontSize: '13px',
                            margin: '4px',
                          }}
                        >
                          (Green Health card)
                        </span>
                      </Typography>
                      {this.renderOhipNumber()}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        className={classes.typography}
                        variant="body1"
                      >
                        OHIP Exp. Date
                      </Typography>
                      {this.renderOhipExpirationDate(isDateSupported())}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.typography}>
                      New picture of your OHIP card (front).
                    </Typography>
                    <br />
                    <div>
                      <DropzoneInputOhip
                        setImageFile={(file) =>
                          this.setState({ imageFile: file })
                        }
                      />
                    </div>
                  </Grid>
                  {/* {this.props.formMeta &&
                    this.props.formMeta.medicalProfile &&
                    ((this.props.formMeta.medicalProfile.ohipNumber &&
                      this.props.formMeta.medicalProfile.ohipNumber.touched) ||
                      (this.props.formMeta.medicalProfile.ohipExpiration &&
                        this.props.formMeta.medicalProfile.ohipExpiration
                          .touched)) && (
                      <Field name="image_error" component={<ImageError />} />
                    )} */}
                </Grid>
                <Grid
                  container
                  direction="row"
                  className={classes.bottomSpacing}
                >
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      className={classes.alignButton}
                      onClick={() => {
                        this.props.closeEditPage();
                      }}
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      type="submit"
                      disabled={
                        pristine || invalid || this.state.imageFile === null
                      }
                      variant="contained"
                      color="primary"
                      className={classes.alignButton}
                      style={{
                        float: 'right',
                      }}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </FormSection>
            <Dialog
              open={this.state.redOhipCardDialog}
              onClose={() => this.setState({ redOhipCardDialog: false })}
            >
              <DialogTitle>Red and White OHIP Card?</DialogTitle>
              <DialogContent>
                <Typography>
                  Please call us at <b>1-833-762-7333</b> for help adding the
                  card to your profile.
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => this.setState({ redOhipCardDialog: false })}
                  variant="contained"
                  color="primary"
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={this.state.ohipReminder}
              onClose={() => {
                document.querySelector('#dashboard-section').scrollIntoView({
                  behavior: 'smooth',
                });
                this.setState({ ohipReminder: false });
                this.props.closeEditPage();
              }}
            >
              <DialogTitle>OHIP Card Information</DialogTitle>
              <DialogContent>
                <Typography>
                  Now that you have uploaded your OHIP card, you can start an
                  OHIP appointment by selecting the SEE DOCTOR button at the top
                  of your dashboard.
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    document
                      .querySelector('#dashboard-section')
                      .scrollIntoView({
                        behavior: 'smooth',
                      });
                    this.props.closeEditPage();
                    this.setState({ ohipReminder: false });
                  }}
                  variant="contained"
                  color="primary"
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </main>
        </Slide>
        <Backdrop className={classes.backdrop} open={this.state.backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Fragment>
    );
  }
}

function validate(values) {
  const errors = { medicalProfile: {} };
  // arguably some of these are not necessary, seeing as the OHIP card validation is done on the server
  // the OHIP number will determine if the card is valid or not
  if (values.medicalProfile) {
    // if (
    //   !values.medicalProfile.files ||
    //   (values.medicalProfile.files && values.medicalProfile.files.length === 0)
    // ) {
    //   errors.medicalProfile.image_error =
    //     'Please upload a picture of your health card';
    // }

    if (!values.medicalProfile.ohipNumber) {
      errors.medicalProfile.ohipNumber = 'Please enter a valid ohip number';
    }

    if (!values.medicalProfile.ohipExpiration) {
      errors.medicalProfile.ohipExpiration = 'Please enter a valid date';
    }

    if (
      values.medicalProfile.ohipNumber &&
      values.medicalProfile.ohipNumber.replace(/[^a-zA-Z0-9]/g, '').length !==
        12
    ) {
      errors.medicalProfile.ohipNumber = 'Please enter a valid ohip number';
    }

    if (
      values.medicalProfile.ohipExpiration &&
      values.medicalProfile.ohipExpiration.replace(/\D+/g, '').length !== 8
    ) {
      const expirationError = () => (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="caption">
              Please enter a valid date.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">YYYY-MM-DD</Typography>
          </Grid>
        </Grid>
      );
      errors.medicalProfile.ohipExpiration = expirationError();
    }
  }
  return errors;
}

function mapStateToProps(state) {
  return {
    form: state.form.dashboardUpdateForm,
    auth: state.auth,
    selectedUser: state.selectedUser,
    selectedUserMedical: state.selectedUserMedical,
    formMeta: getFormMeta('dashboardUpdateForm')(state),
  };
}

EditOhipCard.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedUser: PropTypes.object.isRequired,
  selectedUserMedical: PropTypes.object.isRequired,
  formMeta: PropTypes.object,
  auth: PropTypes.object.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  closeEditPage: PropTypes.func.isRequired,
  editProfile: PropTypes.func.isRequired,
};

SafariDatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

OhipNumberField.propTypes = {
  input: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
};

OhipExpirationDate.propTypes = {
  input: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
};

CustomInput.propTypes = {
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  meta: PropTypes.object.isRequired,
};

const ConnectedEditOhipCard = connect(mapStateToProps, actions)(EditOhipCard);

const EditOhipCardRedux = reduxForm({
  validate,
  form: 'dashboardUpdateForm',
  // forceUnregisterOnUnmount: true // <------ unregister fields on unmount
})(ConnectedEditOhipCard);

export default withStyles(styles)(EditOhipCardRedux);
